import request from '@/utils/request'


// 查询分销配置列表
export function listDistribution(query) {
  return request({
    url: '/user/distribution-config/list',
    method: 'get',
    params: query
  })
}

// 查询分销配置分页
export function pageDistribution(query) {
  return request({
    url: '/user/distribution-config/page',
    method: 'get',
    params: query
  })
}

// 查询分销配置详细
export function getDistribution(data) {
  return request({
    url: '/user/distribution-config/detail',
    method: 'get',
    params: data
  })
}

// 新增分销配置
export function addDistribution(data) {
  return request({
    url: '/user/distribution-config/add',
    method: 'post',
    data: data
  })
}

// 修改分销配置
export function updateDistribution(data) {
  return request({
    url: '/user/distribution-config/edit',
    method: 'post',
    data: data
  })
}

// 删除分销配置
export function delDistribution(data) {
  return request({
    url: '/user/distribution-config/delete',
    method: 'post',
    data: data
  })
}
